@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap');
body {
  font-family: 'Assistant', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
  letter-spacing: 0.16px;
 
background-color: #171718;
color: white;

}
.background{
  background: url("Artboard2.svg");
  background-color: rgb(47, 9, 50);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




